.body-container {
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  min-width: 1366px;
  min-height: 768px;
  position: relative;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin:auto;
  overflow: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

div.header {
  height: 60px;
  width: 100%;
  background: #1C92C7;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
}

.btn-info {
  background-color: #1C92C7;
  cursor: pointer;
}

.btn-info:hover {
  color: gray;
  background-color: #85d3fa;
  cursor: pointer;
}

.btn-info.disabled, .btn-info:disabled {
  color: #999;
  border-color: #999;
  background-color: #dddddd;
  cursor: default;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #999;
  background: #dddddd;
  box-shadow: none;
  outline-color: none;
  cursor: default;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  color: #999;
  background: #dddddd;
  box-shadow: none;
  outline-color: none;
  cursor: default;
}

.btn-outline-info {
  color: #1C92C7;
  border-color: #1C92C7;
  cursor: pointer;
}

.btn-outline-info:hover {
  color: gray;
  background-color: #85d3fa;
  border-color: #1C92C7;
  cursor: pointer;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #999;
  border-color: #999;
  background-color: #dddddd;
  cursor: default;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: gray;
  background-color: #85d3fa;
  border-color: #85d3fa;
  box-shadow: none;
  outline-color: none;
  cursor: default;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
  outline-color: none;
  color: gray;
  background-color: #85d3fa;
  border-color: #dddddd;
  cursor: default;
}

.header-options {
  position: fixed;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-1 {
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.drawer {
  height: 100%;
  background: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1;
  top: 60;
  left: 0;
  width: 50px;
  max-width: 300px;
  transition: width 0.3s ease-out;
}

.drawer.open {
  width: 250px;
}

i {
  position: relative;
  font-size: 16px;
  font-weight: bold;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ddd;
  color: black;
  padding: 10px 20px;
  border-radius: 6px;
  white-space: normal;
  width: 100%;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  font-size: 14px;
  line-height: 1.5;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

i:hover + .tooltip {
  opacity: 1;
  visibility: visible;
}

.table-holder {
    height: calc(100% - 220px);
    display: block;
    position: absolute;
    width: 100%;
    transition: height 0.3s ease-out;
}

.searh-bar.open + .table-holder {
  height: calc(100% - 400px);
}

.searh-bar {
  width: 100%;
  height: 30px;
  transition: height 0.3s ease-in;
}

.searh-bar.open {
  height: 220px;
}

.card {
  margin-left: auto;
  margin-right: auto;
}

.body-width {
  margin-left: 50px;
  width: 100%;
  height: calc(100vh - 60px);
  position: relative;
  transition: margin-left 0.3s ease-out;
}

.body-width.open {
  width: 100%;
  margin-left: 250px;
}

.edit-view-create.open {
  margin-left: calc(100vw - 82vw - 250px);
}

.edit-view.open {
  margin-left: calc(100vw - 82vw - 250px);
}

.topright-div.open {
  margin-right: 260px;
}

.edit-view-create {
  position: fixed;
  top: 120px;
  display:block;
  width: 100vw;
  min-width: 1650px;
  min-height: 650px;
  margin-left: calc(100vw - 82vw - 50px);
  overflow: auto;
  height: calc(100vh - 180px);
  transition: margin-left 0.3s ease-out;
}

.edit-view {
  position: fixed;
  top: 170px;
  display:block;
  width: 100vw;
  min-width: 1650px;
  /*min-height: 650px;*/
  margin-left: calc(100vw - 82vw - 50px);
  overflow: auto;
  height: calc(100vh - 25vh);
  transition: margin-left 0.3s ease-out;
}

.drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.row {
  margin: 0px; 
}

.row-login {
  min-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
}

.row-login img { 
    width:100%;
    height:100%; 
    object-fit:cover; 
}

.drawer li {
  margin: 0.5rem 0;
}

.drawer a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
}

.drawer li:hover {
  background-color: #e9ecef;
}

.drawer drawer-li:hover {
  background-color: white;
}

.header-span:hover {
  background-color: #65a4c4;
}

.fixed_header{
  table-layout: fixed;
  border-collapse: collapse;
  height: 70vh;
}

.fixed_header tbody{
  display:block;
  overflow: auto;
  height: 100%;
}

.fixed_header thead tr {
   display: block;
}

.fixed_header thead {
  background: gray;
  color:#fff;
}

.fixed_header_div {
  text-align: center;
  background: white;
  color: #696765;
  min-height: 34px;
}

.fixed_header_div div {
  padding-top: 4px;
  border-width: 1px;
  border-style: solid double solid none;
  border-color: #b5b1af;
}

.fixed_div {
  display:block;
  overflow: auto;
  height: calc(100% - 30px);
  max-height: 100%;
}

.fixed_header th, .fixed_header td {
  padding: 5px;
  text-align: left;
}

.body_div {
  height: calc(100% - 200px);
}

.edit-form-view {
  height: calc(100% - 200px);
}

.FlexItem {
  height: calc(100vh - 60px);
  width: 66.6%;
  flex: 0 1 auto;
}

.FlexItem img {
  display: block;
  width: 100%;
}

.login-input-vertical-align {
  padding-top: 10%;
}

.bottomright {
  position: fixed;
  font-size: 18px;
  color: grey;
  margin-top: 20px;
  right: -30px;
  bottom: 10px;
  min-width: 300px;
}

.bottomright-ie {
  position: absolute;
  bottom: 5px;
  font-size: 18px;
  color: grey;
  margin-top: 20px;
  right: 25px;
}

.topright {
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 18px;
}

.topright-div {
  position: absolute;
  top: 65px;
  right: 0px;
  margin-right: 60px;
  transition: margin-right 0.3s ease-out;
}

.status-placeholder {
  padding-top: 5px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  list-style: none;
  height: 38px;
}

.bg-green {
  background-color: #e7ffdc;
  color: green;
}

.bg-grey {
  background-color: #d2d2d2;
  color: grey;
}

.bg-red {
  background-color: #efb4af;
  color: red;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}

.popup_inner {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 350px;
  height: 300px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup_inner_2f {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 400px;
/*  height: 345px;*/
  left: 25%;
  right: 25%;
  top: 25%;
  /*bottom: 25%;*/
  margin: auto;
  background: white;
}

.popup_inner_form {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  max-width: 600px;
/*  height: 345px;*/
  left: 25%;
  right: 25%;
  top: 25%;
  /*bottom: 25%;*/
  margin: auto;
  background: white;
}

.popup_inner_about {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 400px;
  height: 235px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup_inner_confirm {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 500px;
  height: 175px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.popup_inner_error {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 350px;
  height: 320px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
  overflow: hidden;
}

.popup_inner_user_form {
  height: 325px;
  overflow-y: scroll;
}

.popup_inner_user_token_pin {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  max-width: 400px;
  min-height: 140px;
  position: relative;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
  background: white;
  overflow: hidden;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: local('ProximaNova-Regular'), url(../fonts/12093.ttf) format('truetype');
}